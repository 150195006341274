import { alert, Dialog } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { ProcedureWithAppointmentType } from 'types/procedures'

import api from 'api/api'

type MergeProcedureProps = {
  isOpen: boolean
  onClose: () => void
  mergePair: {
    source: ProcedureWithAppointmentType
    target: ProcedureWithAppointmentType
  } | null
  refreshData: () => void
}

const MergeProcedure = ({
  isOpen,
  onClose,
  mergePair,
  refreshData,
}: MergeProcedureProps) => {
  const { t } = useTranslation()

  const handleMerge = async () => {
    if (!mergePair) return

    await api.mergeAppointment(
      mergePair.target.appointment.id,
      mergePair.source.treatmentProcedureId,
    )

    refreshData()
    alert.success(t('Success'), t('Events merged successfully.'), {
      position: 'bottom-right',
    })
    onClose()
  }

  return (
    <Dialog
      open={isOpen && !!mergePair}
      variant="confirm"
      onConfirm={handleMerge}
      closeFn={onClose}
      title={t('Confirm Merge')}
      description={
        mergePair
          ? t('Do you want to merge "{{source}}" into "{{target}}"?', {
              source: mergePair.source.name,
              target: mergePair.target.name,
            })
          : ''
      }
      confirmButtonText={t('Confirm')}
      cancelButtonText={t('Cancel')}
    />
  )
}

export default MergeProcedure
