import { Route, Routes } from 'react-router-dom'

import Holidays from 'pages/Holidays'
import NewOrganization from 'pages/Organizations/NewOrganization'
import OrganizationEdit from 'pages/Organizations/OrganizationEdit'
import OrganizationsList from 'pages/Organizations/OrganizationsList'
import OrganzationView from 'pages/Organizations/OrganizationView'
import Providers from 'pages/Providers'
import Rooms from 'pages/Rooms'
import {
  ProvidersPath,
  GenericPath,
  RoomsPath,
  HolidaysPath,
} from 'router/enums'

const Organizations = () => (
  <Routes>
    <Route index element={<OrganizationsList />} />
    <Route path={GenericPath.NEW} element={<NewOrganization />} />
    <Route path={GenericPath.ENTITY} element={<OrganzationView />} />
    <Route
      path={`${GenericPath.ENTITY}/${GenericPath.EDIT}`}
      element={<OrganizationEdit />}
    />
    <Route
      path={`${GenericPath.ENTITY}/${ProvidersPath.PROVIDERS}/*`}
      element={<Providers />}
    />
    <Route
      path={`${GenericPath.ENTITY}/${RoomsPath.ROOMS}/*`}
      element={<Rooms />}
    />
    <Route
      path={`${GenericPath.ENTITY}/${HolidaysPath.HOLIDAYS}/*`}
      element={<Holidays />}
    />
  </Routes>
)

export default Organizations
