import { alert } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { HolidayInputType } from 'types/holidays'

import api from 'api/api'
import Card from 'components/Card'
import HolidayForm from 'components/Holidays/HolidayForm'
import { HolidayFields } from 'components/Holidays/utils'
import PageTemplate from 'components/PageTemplate'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { HolidayInputSchema } from 'schemas/Holiday'
import { HolidayTypes } from 'utils/enums'

const NewHoliday = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const goBackToUrl = React.useMemo(
    () => `${RootPath.ORGANIZATIONS}/${id}`,
    [id],
  )

  const form = useFormManager<HolidayInputType>({
    defaultValues: {
      [HolidayFields.ORGANIZATION_ID]: id,
      [HolidayFields.HOLIDAYS]: [
        {
          [HolidayFields.NAME]: '',
          [HolidayFields.BEGIN_DATE_TIME]: '',
          [HolidayFields.END_DATE_TIME]: '',
          [HolidayFields.TYPE]: HolidayTypes.HOLIDAY,
        },
      ],
    },
    enableReinitialize: true,
    schema: HolidayInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async (input: HolidayInputType) => {
      await api.addHoliday(input)
      navigate(goBackToUrl)
      alert.success(
        `${t('Success')}!`,
        `${t('Holiday was created successfully')}.`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New Holidays')}
      goBackTo={goBackToUrl}
      fullWidth={false}
      numberOfElements={1}
    >
      <Card>
        <HolidayForm {...form} />
      </Card>
    </PageTemplate>
  )
}

export default NewHoliday
