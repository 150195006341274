import * as yup from 'yup'

import type { HolidayInputType } from 'types/holidays'

import { HolidayFields } from 'components/Holidays/utils'
import i18next from 'i18n'
import { HolidayTypes } from 'utils/enums'
import { dateTimeRegex } from 'utils/time'

export const HolidayInputSchema: yup.Schema<HolidayInputType> = yup
  .object()
  .shape({
    [HolidayFields.ORGANIZATION_ID]: yup
      .string()
      .required(i18next.t('Organization id is required')),
    [HolidayFields.HOLIDAYS]: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().optional(),
          name: yup.string().required(i18next.t('Name is required')),
          beginDateTime: yup
            .string()
            .matches(dateTimeRegex, i18next.t('Invalid format'))
            .required(i18next.t('Start date is required')),

          endDateTime: yup
            .string()
            .matches(dateTimeRegex, i18next.t('Invalid format'))
            .required(i18next.t('End date is required'))
            .test(
              'is-after-start',
              i18next.t('End date must be after start date'),
              function(value) {
                const { beginDateTime } = this.parent
                return (
                  !beginDateTime ||
                  !value ||
                  new Date(value) > new Date(beginDateTime)
                )
              },
            ),
          type: yup
            .string()
            .oneOf(Object.values(HolidayTypes))
            .required(i18next.t('Type is required')),
        }),
      )
      .required()
      .default([]),
  })
