import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import { Input, Flex, Button, Text } from '@weareredlight/design-system'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { HolidayInputType } from 'types/holidays'
import type { FormType } from 'types/types'

import { ControlledSelect, Form } from 'components/Form'
import { ControlledDateTimePicker } from 'components/Form/ControlledDateTimePicker'
import { HolidayFields } from 'components/Holidays/utils'
import { HolidayTypes } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { holidayTypeLabels } from 'utils/labels'

type HolidayFormProps = FormType<HolidayInputType> & {
  editModal?: boolean
}

const HolidayForm = ({
  form,
  editModal = false,
  ...props
}: HolidayFormProps) => {
  const { t } = useTranslation()

  const {
    fields: holidays,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: HolidayFields.HOLIDAYS,
  })

  return (
    <Form form={form} {...props}>
      <Flex direction="column" align="start" gap="xlg" className="form-content">
        {!editModal && <Text variant="h2">{t('Details')}</Text>}
        <Flex align="start" gap="xlg" className="fields-wrapper">
          <Flex
            direction="column"
            align="start"
            gap="sm"
            css={{ width: '100%' }}
          >
            {holidays.map((item, index) => (
              <Flex
                key={item.id}
                align="start"
                gap="xxsm"
                css={{ width: '100%' }}
              >
                <Flex
                  align="start"
                  gap="sm"
                  css={{ width: '100%', '@md': { flexDirection: 'column' } }}
                >
                  <Input
                    {...form.register(
                      `${HolidayFields.HOLIDAYS}.${index}.${HolidayFields.NAME}`,
                    )}
                    label={t('Name')}
                    placeholder={t('Type the holiday name')}
                    state={
                      form.formState.errors[HolidayFields.HOLIDAYS]?.[index]?.[
                        HolidayFields.NAME
                      ]
                        ? 'error'
                        : 'null'
                    }
                    errorMsg={
                      form.formState.errors[HolidayFields.HOLIDAYS]?.[index]?.[
                        HolidayFields.NAME
                      ]?.message
                    }
                    fullWidth
                  />

                  <ControlledDateTimePicker
                    name={`${HolidayFields.HOLIDAYS}.${index}.${HolidayFields.BEGIN_DATE_TIME}`}
                    labelDate={t('Start Date')}
                    labelTime={t('Start Time')}
                    error={
                      form.formState.errors[HolidayFields.HOLIDAYS]?.[index]?.[
                        HolidayFields.BEGIN_DATE_TIME
                      ]?.message
                    }
                  />

                  <ControlledDateTimePicker
                    name={`${HolidayFields.HOLIDAYS}.${index}.${HolidayFields.END_DATE_TIME}`}
                    labelDate={t('End Date')}
                    labelTime={t('End Time')}
                    error={
                      form.formState.errors[HolidayFields.HOLIDAYS]?.[index]?.[
                        HolidayFields.END_DATE_TIME
                      ]?.message
                    }
                  />

                  <ControlledSelect
                    name={`${HolidayFields.HOLIDAYS}.${index}.${HolidayFields.TYPE}`}
                    options={enum2SelectOptions(
                      HolidayTypes,
                      holidayTypeLabels,
                    )}
                    label={t('Type')}
                    placeholder={t('Select type')}
                    state={
                      form.formState.errors[HolidayFields.HOLIDAYS]?.[index]?.[
                        HolidayFields.TYPE
                      ]
                        ? 'error'
                        : 'null'
                    }
                    fullWidth
                  />
                </Flex>

                {!editModal && (
                  <Button
                    variant="textOnly"
                    iconPosition="iconOnly"
                    disabled={holidays.length === 1}
                    onClick={() => remove(index)}
                    iconComponent={() => <Cross1Icon />}
                    css={{ alignSelf: 'center' }}
                  />
                )}
              </Flex>
            ))}
            {!editModal && (
              <Button
                iconComponent={() => <PlusIcon />}
                onClick={() =>
                  append({
                    [HolidayFields.NAME]: '',
                    [HolidayFields.BEGIN_DATE_TIME]: '',
                    [HolidayFields.END_DATE_TIME]: '',
                    [HolidayFields.TYPE]: HolidayTypes.HOLIDAY,
                  })
                }
              >
                {t('Add Holiday')}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Form>
  )
}

export default HolidayForm
