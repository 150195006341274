import { useDroppable } from '@dnd-kit/core'

import type { UniqueIdentifier } from '@dnd-kit/core'
import type { Row } from '@tanstack/react-table'

type DraggableRowProps<T> = {
  row: Row<T>
  getRowId: (row: T) => string
  dragOverId: UniqueIdentifier | null
}

const DraggableRow = <T extends object>({
  row,
  getRowId,
  dragOverId,
}: DraggableRowProps<T>) => {
  const id = getRowId(row.original)

  const { setNodeRef, isOver } = useDroppable({ id })

  const isHovering = dragOverId === id || isOver

  return (
    <tr
      ref={setNodeRef}
      style={{
        backgroundColor: isHovering ? '#f0f8ff' : 'inherit',
        transition: 'background-color 0.2s ease-in-out',
      }}
    >
      {row.getVisibleCells().map(cell => (
        <td key={cell.id}>
          {typeof cell.column.columnDef.cell === 'function'
            ? cell.column.columnDef.cell(cell.getContext())
            : cell.getValue()}
        </td>
      ))}
    </tr>
  )
}

export default DraggableRow
