import { Cross1Icon, Pencil1Icon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Flex, Button } from '@weareredlight/design-system'

import type { ColumnSort } from '@tanstack/react-table'
import type { HolidayResponseType } from 'types/holidays'

import { HolidayActions } from 'components/Holidays/utils'
import { defaultColumnOptions } from 'utils/table'
import { format, APPOINTMENT_FORMAT } from 'utils/time'

export const holidaysDefaultSortees: ColumnSort[] = [
  { id: 'name', desc: false },
]

const columnHelper = createColumnHelper<HolidayResponseType>()

export const holidaysColumns = (
  handleAction: (holiday: HolidayResponseType, action: HolidayActions) => void,
) => [
  columnHelper.accessor('name', {
    ...defaultColumnOptions<HolidayResponseType>(),
    meta: { width: '30%' },
    enableColumnFilter: false,
    header: 'Name',
  }),
  columnHelper.accessor('type', {
    ...defaultColumnOptions<HolidayResponseType>(),
    meta: { width: '10%' },
    enableColumnFilter: false,
    enableSorting: true,
    header: 'Type',
  }),
  columnHelper.accessor('beginDateTime', {
    ...defaultColumnOptions<HolidayResponseType>(),
    meta: { width: '25%' },
    enableColumnFilter: false,
    enableSorting: true,
    header: 'Start date',
    cell: ({ row: { original } }) =>
      (original.beginDateTime
        ? format(original.beginDateTime, APPOINTMENT_FORMAT)
        : '--'),
  }),
  columnHelper.accessor('endDateTime', {
    ...defaultColumnOptions<HolidayResponseType>(),
    meta: { width: '25%' },
    enableColumnFilter: false,
    enableSorting: true,
    header: 'End date',
    cell: ({ row: { original } }) =>
      (original.endDateTime
        ? format(original.endDateTime, APPOINTMENT_FORMAT)
        : '--'),
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    meta: { width: '10%' },
    cell: ({ row: { original } }) => {
      return (
        <Flex gap="xxsm" css={{ width: '100%' }}>
          <Button
            variant={'secondary'}
            onClick={() => handleAction(original, HolidayActions.EDIT)}
            iconComponent={() => <Pencil1Icon />}
            iconPosition="iconOnly"
            css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
          />
          <Button
            variant="neutral"
            onClick={() => handleAction(original, HolidayActions.DELETE)}
            iconComponent={() => <Cross1Icon />}
            css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
          />
        </Flex>
      )
    },
  }),
]
