import { alert, Dialog } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { HolidayResponseType } from 'types/holidays'

import api from 'api/api'

type DeleteHolidayProps = {
  holidayId: string
  isOpen: boolean
  onClose: (holiday?: HolidayResponseType) => void
  refreshData: () => void
}

const DeleteHoliday = ({
  holidayId,
  isOpen,
  onClose,
  refreshData,
}: DeleteHolidayProps) => {
  const { t } = useTranslation()

  const handleDeleteShift = async () => {
    await api.deleteHoliday(holidayId)
    refreshData()
    alert.success(`${t('Success')}!`, t('Holiday has been removed.'), {
      position: 'bottom-right',
    })
  }

  return (
    <Dialog
      open={isOpen && !!holidayId}
      variant="danger"
      onConfirm={handleDeleteShift}
      closeFn={onClose}
      title={t('Confirm the holiday deletion')}
      description={t('This will delete the holiday permanently.')}
      confirmButtonText={t('Delete')}
      cancelButtonText={t('Cancel')}
    />
  )
}

export default DeleteHoliday
