import { Flex, Text } from '@weareredlight/design-system'
import dayjs from 'dayjs'
import { useMemo } from 'react'

type Holiday = {
  id: string
  name: string
  beginDateTime: string
  endDateTime: string
}

type DateHeaderProps = {
  label: string
  date: Date
  holidays?: Holiday[] | null
}

export const DateHeader = ({ label, date, holidays }: DateHeaderProps) => {
  const matchingHolidays = useMemo(() => {
    return holidays?.filter(h =>
      dayjs(date).isBetween(
        dayjs(h.beginDateTime),
        dayjs(h.endDateTime),
        'day',
        '[]',
      ),
    )
  }, [date, holidays])

  const holidayText = matchingHolidays?.map(h => h.name).join(', ')

  return (
    <Flex
      direction="column"
      align="center"
      style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
    >
      <Text>{label}</Text>
      {holidayText && (
        <Text variant="microCopy" color="danger">
          {holidayText}
        </Text>
      )}
    </Flex>
  )
}
