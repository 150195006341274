import { useEffect } from 'react'

import type { HolidayResponseType, HolidayParamsType } from 'types/holidays'
import type { PaginatedRequest } from 'types/types'

import api from 'api/api'
import { useRequest } from 'hooks/useRequest'

export const useHolidays = (params?: HolidayParamsType) => {
  const {
    data: holidaysPaginated,
    doRequest: getHolidays,
    isLoading,
    error,
  } = useRequest<PaginatedRequest<HolidayResponseType>, HolidayParamsType>(
    api.getHolidays,
  )

  useEffect(() => {
    if (params) getHolidays(params)
  }, [getHolidays, params])

  return {
    holidays: holidaysPaginated?.data || [],
    isLoading,
    error,
    refresh: () => (params ? getHolidays(params) : {}),
  }
}
