import { Flex } from '@weareredlight/design-system'
import { DatePicker } from '@weareredlight/design-system'
import { TimePicker } from '@weareredlight/design-system'
import { useEffect, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { PickerTypes } from './ControlledTimePicker'

import { format, DATE_FORMAT } from 'utils/time'

type ControlledDateTimePickerProps = {
  name: string
  labelDate?: string
  labelTime?: string
  error?: string | null
  fullWidth?: boolean
}

export const ControlledDateTimePicker = ({
  name,
  labelDate,
  labelTime,
  error,
}: ControlledDateTimePickerProps) => {
  const { control, watch, setValue } = useFormContext()

  const dateKey = `${name}_date`
  const timeKey = `${name}_time`

  const fullDateTime = watch(name, '') as string
  const selectedDate = watch(dateKey, '') as string
  const selectedTime = watch(timeKey, '00:00:00') as string

  useEffect(() => {
    if (fullDateTime) {
      const [date, time] = fullDateTime.split('T')
      if (date) setValue(dateKey, format(date, DATE_FORMAT))
      if (time) setValue(timeKey, time)
    }
  }, [fullDateTime, setValue, dateKey, timeKey])

  const updateDateTime = useCallback(() => {
    if (!selectedDate) {
      setValue(name, '')
      return
    }

    const formattedDateTime = `${format(selectedDate, DATE_FORMAT)}T${
      selectedTime || '00:00:00'
    }`
    setValue(name, formattedDateTime)
  }, [selectedDate, selectedTime, name, setValue])

  useEffect(() => {
    updateDateTime()
  }, [selectedDate, selectedTime, updateDateTime])

  return (
    <Flex direction="row" gap="sm" css={{ width: '100%' }}>
      <Controller
        name={dateKey}
        control={control}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            label={labelDate}
            value={value ?? ''}
            onChange={value =>
              onChange(value ? format(value as string | Date) : null)
            }
            error={error}
          />
        )}
      />
      <Controller
        name={timeKey}
        control={control}
        render={({ field: { value, onChange } }) => (
          <TimePicker
            type={PickerTypes.TIME}
            label={labelTime}
            value={value ?? '00:00:00'}
            onChange={selected => {
              onChange(selected || '00:00:00')
            }}
            error={error}
          />
        )}
      />
    </Flex>
  )
}
