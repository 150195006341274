import type { HolidayRequestType } from 'types/holidays'

export enum HolidayFields {
  HOLIDAYS = 'holidays',
  NAME = 'name',
  BEGIN_DATE_TIME = 'beginDateTime',
  END_DATE_TIME = 'endDateTime',
  ORGANIZATION_ID = 'organizationId',
  TYPE = 'type',
  ID = 'id',
}

export enum HolidayActions {
  EDIT = 'edit',
  DELETE = 'delete',
  NEW = 'new',
}

export const mapApi2Form = (data: HolidayRequestType, id: string) => ({
  [HolidayFields.NAME]: data.name,
  [HolidayFields.BEGIN_DATE_TIME]: data.beginDateTime,
  [HolidayFields.END_DATE_TIME]: data.endDateTime,
  [HolidayFields.ORGANIZATION_ID]: id,
  [HolidayFields.TYPE]: data.type,
})
