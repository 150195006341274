import {
  CaretSortIcon,
  CaretDownIcon,
  CaretUpIcon,
  MagnifyingGlassIcon,
} from '@radix-ui/react-icons'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Flex, Input, Text, Pagination } from '@weareredlight/design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  ColumnDef,
  SortingState,
  ColumnSort,
  OnChangeFn,
  ColumnFiltersState,
  Row,
} from '@tanstack/react-table'
import type { ReactElement } from 'react'

import {
  StyledTable,
  StyledTableBody,
  StyledTableHead,
  StyledEmptyRow,
} from './table.styles'

import Card from 'components/Card'
import { globalFilter, globalSort } from 'utils/table'

type Props<T> = {
  data: T[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[]
  sortees?: ColumnSort[]
  renderOptions?: (data: T) => ReactElement
  onSort?: (newSort: ColumnSort[]) => void
  sorting?: SortingState
  setSorting?: OnChangeFn<SortingState>
  setColumnFilters?: OnChangeFn<ColumnFiltersState>
  columnFilters?: ColumnFiltersState
  handlePageChange?: (pageNumber: number) => void
  currentPage?: number
  totalPages: number
  pageSize?: number
  totalCount: number
  onRowClick?: (data: T) => void
  isLoading?: boolean
  hiddenColumns?: string[]
  renderRow?: (row: Row<T>) => React.ReactNode
}

const Table = <T extends object>({
  data,
  columns,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  renderOptions,
  currentPage,
  totalPages,
  handlePageChange,
  pageSize,
  totalCount,
  isLoading,
  onRowClick,
  hiddenColumns,
  renderRow,
}: Props<T>) => {
  const { t } = useTranslation()

  const columnVisibility = useMemo(() => {
    const visibility: { [key: string]: boolean } = {}
    hiddenColumns?.forEach(column => (visibility[column] = false))
    return visibility
  }, [hiddenColumns])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // Filtering
    enableFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: { globalFilter: globalFilter<T> },
    onColumnFiltersChange: setColumnFilters,
    // Sorting
    enableSorting: true,
    manualSorting: true,
    state: { sorting, columnFilters, columnVisibility },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,
    sortingFns: { globalSort: globalSort<T> },
  })

  const getItemsPerPage = useMemo(() => {
    if (!pageSize || !currentPage) return ''
    const lastPage = Math.ceil(totalCount / pageSize)

    const startItem = (currentPage - 1) * pageSize + 1
    const isTotalCount =
      currentPage === lastPage || currentPage * pageSize > totalCount
    const endItem = isTotalCount ? totalCount : currentPage * pageSize

    return `${startItem} - ${endItem} ${t('of')} ${totalCount}`
  }, [currentPage, pageSize, totalCount, t])

  return (
    <Flex direction="column" align="end" gap="xsm" style={{ width: '100%' }}>
      <Card extraClasses="no-padding full-width is-table" isLoading={isLoading}>
        <StyledTable>
          <StyledTableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={'headergroup' + headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const { column } = header
                  if (column?.columnDef?.header)
                    column.columnDef.header = t(
                      column.columnDef.header.toString(),
                    )
                  const columnFilterValue = column.getFilterValue()
                  const isSortable = column.getCanSort()

                  const columnWidth =
                    (column.columnDef.meta as { width?: string })?.width ??
                    'auto'

                  return (
                    <th
                      key={'header' + header.id}
                      style={{ width: columnWidth }}
                      className={!isSortable ? 'disabled ' : ''}
                    >
                      <button
                        onClick={
                          (isSortable && column.getToggleSortingHandler()) ||
                          undefined
                        }
                      >
                        <Text variant="subHeading" color="neutral800">
                          {flexRender(
                            column.columnDef.header,
                            header.getContext(),
                          )}
                        </Text>
                        {(isSortable &&
                          {
                            asc: <CaretDownIcon />,
                            desc: <CaretUpIcon />,
                          }[column.getIsSorted() as string]) ?? (
                          <CaretSortIcon />
                        )}
                      </button>
                      {header.column.getCanFilter() ? (
                        <Input
                          type="text"
                          id={`${column.id}list`}
                          variant="simple"
                          value={(columnFilterValue ?? '') as string}
                          placeholder={t('Filter by')}
                          iconPosition="left"
                          iconComponent={() => <MagnifyingGlassIcon />}
                          onChange={({ target: { value } }) =>
                            column.setFilterValue(value)
                          }
                          fullWidth
                        />
                      ) : null}
                    </th>
                  )
                })}
              </tr>
            ))}
          </StyledTableHead>
          <StyledTableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map(row =>
                (renderRow ? (
                  renderRow(row)
                ) : (
                  <tr
                    key={'tablerow' + row.id}
                    onClick={onRowClick && (() => onRowClick(row.original))}
                    className={onRowClick ? 'clickable-row' : ''}
                  >
                    {row.getVisibleCells().map(cell => {
                      const data = cell.row.original
                      const toRender =
                        renderOptions && cell.column.id === 'actions' ? (
                          <Flex>{renderOptions(data)}</Flex>
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )
                        )
                      return <td key={cell.id}>{toRender}</td>
                    })}
                  </tr>
                )),
              )
            ) : (
              <tr>
                <StyledEmptyRow colSpan={1000}>
                  <Text variant="textBlock" color="neutral">
                    {t('Nothing was found')}...
                  </Text>
                </StyledEmptyRow>
              </tr>
            )}
          </StyledTableBody>
        </StyledTable>
      </Card>
      {handlePageChange && totalCount > 0 && (
        <Flex gap="xxxsm">
          <Text color="primary" variant="subHeading">
            {getItemsPerPage}
          </Text>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            variant="minimal"
          />
        </Flex>
      )}
    </Flex>
  )
}

export default Table
