import { useDraggable } from '@dnd-kit/core'
import { DragHandleDots2Icon } from '@radix-ui/react-icons'
import { Flex } from '@weareredlight/design-system'

import type { UniqueIdentifier } from '@dnd-kit/core'

type Props = {
  rowId: UniqueIdentifier
}

const RowDragHandleCell = ({ rowId }: Props) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: rowId,
  })

  return (
    <Flex
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DragHandleDots2Icon />
    </Flex>
  )
}

export default RowDragHandleCell
