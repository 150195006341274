import { Route, Routes } from 'react-router-dom'

import NewHoliday from 'pages/Holidays/NewHoliday'
import { GenericPath } from 'router/enums'

const Holidays = () => (
  <Routes>
    <Route path={GenericPath.NEW} element={<NewHoliday />} />
  </Routes>
)

export default Holidays
