import { alert, Modal } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { HolidayInputType, HolidayResponseType } from 'types/holidays'

import HolidayForm from './HolidayForm'

import api from 'api/api'
import { useFormManager } from 'hooks/useFormManager'
import { HolidayInputSchema } from 'schemas/Holiday'
import { HolidayTypes } from 'utils/enums'

type EditHolidayProps = {
  isOpen: boolean
  onClose: (holiday?: HolidayResponseType) => void
  holiday?: HolidayResponseType
  isLoading: boolean
  refreshData: () => void
}

const EditHoliday = ({
  isOpen,
  onClose,
  holiday,
  isLoading,
  refreshData,
}: EditHolidayProps) => {
  const { t } = useTranslation()

  const form = useFormManager<HolidayInputType>({
    defaultValues: {
      organizationId: holiday?.organizationId || '',
      holidays: [
        {
          id: holiday?.id || '',
          name: holiday?.name || '',
          beginDateTime: holiday?.beginDateTime || '',
          endDateTime: holiday?.endDateTime || '',
          type: holiday?.type || HolidayTypes.HOLIDAY,
        },
      ],
    },
    onCancel: () => {
      form.form.reset()
      onClose()
    },
    enableReinitialize: true,
    schema: HolidayInputSchema,
    onSubmit: async (input: HolidayInputType) => {
      await api.updateHoliday({ input })
      refreshData()
      alert.success(`${t('Success')}!`, t('Holiday updated successfully.'), {
        position: 'bottom-right',
      })
      onClose()
      form.form.reset()
    },
  })

  return (
    <Modal
      open={isOpen && !!holiday?.id}
      title={t('Edit Holiday')}
      closeFn={onClose}
      css={{ maxWidth: '1200px' }}
    >
      <HolidayForm {...form} editModal={true} isLoading={isLoading} />
    </Modal>
  )
}

export default EditHoliday
