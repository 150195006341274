import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import { alert, Button, Flex, Text } from '@weareredlight/design-system'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'
import type { Row } from '@tanstack/react-table'
import type { AppointmentType } from 'types/appointments'
import type { ProcedureWithAppointmentType } from 'types/procedures'
import type { TreatmentType } from 'types/treatments'

import { ReactComponent as MagicIcon } from '../../assets/img/magic.svg'

import MergeProcedure from './MergeProcedure'

import api from 'api/api'
import Card from 'components/Card'
import Table from 'components/Table'
import DraggableRow from 'components/Table/DraggableRow'
import CancellationReasons from 'components/Treatments/CancellationReasons'
import CancelTreatmentProcedures from 'components/Treatments/CancelTreatmentProcedures'
import NewTreatmentProcedures from 'components/Treatments/NewTreatmentProcedures'
import RestoreTreatmentProcedures from 'components/Treatments/RestoreTreatmentProcedures'
import ScheduleAssistTreatment from 'components/Treatments/ScheduleAssistTreatment'
import StatusTag from 'components/Treatments/StatusTag'
import TreatmentCancellation from 'components/Treatments/TreatmentCancellation'
import { useRequest } from 'hooks/useRequest'
import { useSchedule } from 'pages/Schedule/ScheduleAppointment'
import { useCurrentUser } from 'userContext'
import { treatmentProceduresColumns } from 'utils/tables/treatmentProcedures'
import { getName } from 'utils/text'

const TreatmentAppointmentsList = () => {
  const { t } = useTranslation()
  const { isAdmin } = useCurrentUser()
  const {
    treatment,
    appointments,
    isLoading,
    updateTreatment,
    isCancelledTreatment,
  } = useSchedule()

  const { doRequest: downloadTreatment, isLoading: isDownloading } = useRequest<
    string,
    { id: string }
  >(api.downloadTreatment, {
    onSuccess: data => {
      const pdf = new Blob([data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(pdf)
      link.setAttribute(
        'download',
        `${getName(treatment?.patient)}-${t('TREATMENT SCHEDULE')}.pdf`,
      )
      link.click()
      alert.success(
        `${t('Success')}!`,
        t('Treatment PDF downloaded successfully'),
        {
          position: 'bottom-right',
        },
      )
    },
    onError: () => {
      alert.error(`${t('Error')}!`, t('Error downloading treatment PDF'), {
        position: 'bottom-right',
      })
    },
  })

  const [dragOverId, setDragOverId] = useState<UniqueIdentifier | null>(null)
  const [activeDragItem, setActiveDragItem] =
    useState<ProcedureWithAppointmentType | null>(null)

  const [proceduresModalOpen, setProceduresModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [
    treatmentScheduleAssistModalOpen,
    setTreatmentScheduleAssistModalOpen,
  ] = useState(false)
  const [procedureToCancel, setProcedureToCancel] = useState<string | null>(
    null,
  )
  const [procedureToRestore, setProcedureToRestore] = useState<string | null>(
    null,
  )
  const [procedures, setProcedures] = useState<ProcedureWithAppointmentType[]>(
    [],
  )

  // Nest Procedures with Appointments
  const proceduresWithAppointments: ProcedureWithAppointmentType[] =
    useMemo(() => {
      if (!treatment?.procedures || !appointments?.data) return []

      const mapped = treatment.procedures.map(({ id, status, procedure }) => {
        const appointment = appointments.data.find(
          appt => appt.treatmentProcedures?.some(tp => tp.id === id),
        )

        return {
          ...procedure,
          status,
          treatmentProcedureId: id,
          appointment: appointment || ({} as AppointmentType),
        }
      })

      return mapped.sort((a, b) => {
        const aTime = a.appointment?.startTime
          ? new Date(a.appointment.startTime).getTime()
          : Infinity
        const bTime = b.appointment?.startTime
          ? new Date(b.appointment.startTime).getTime()
          : Infinity

        return aTime - bTime
      })
    }, [appointments?.data, treatment?.procedures])

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  )

  const [mergePair, setMergePair] = useState<{
    source: ProcedureWithAppointmentType
    target: ProcedureWithAppointmentType
  } | null>(null)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (!over || active.id === over.id) return

    const source = procedures.find(p => p.treatmentProcedureId === active.id)
    const target = procedures.find(p => p.treatmentProcedureId === over.id)

    if (
      source &&
      target &&
      (!source.appointment?.startTime ||
        source.appointment?.startTime === '') &&
      target.appointment?.startTime
    ) {
      setMergePair({ source, target })
    }
  }

  useEffect(() => {
    setProcedures(proceduresWithAppointments)
  }, [proceduresWithAppointments])

  return (
    <Card extraClasses="wrapper full-width" isLoading={isLoading}>
      <Flex direction="column" gap="xlg">
        <Flex justify="spaceBetween" css={{ width: '100%' }} gap="xxsm" wrap>
          <Flex gap="xxsm" justify="start">
            <Text variant="h3" color="accent">
              {getName(treatment?.patient)}
            </Text>
            {treatment?.status && <StatusTag status={treatment.status} />}
          </Flex>
          {!isCancelledTreatment && isAdmin && (
            <Flex gap="xxsm" justify="start" wrap>
              <Button
                variant="neutral"
                onClick={() => setCancelModalOpen(!cancelModalOpen)}
              >
                {t('Cancel Treatment')}
              </Button>
              <Button
                onClick={() => setProceduresModalOpen(!proceduresModalOpen)}
              >
                {t('Add Procedures')}
              </Button>
              <Button
                variant="success"
                iconComponent={() => <MagicIcon />}
                onClick={() =>
                  setTreatmentScheduleAssistModalOpen(
                    !treatmentScheduleAssistModalOpen,
                  )
                }
              >
                {t('Schedule Assist')}
              </Button>
            </Flex>
          )}
          <Flex gap="xxsm" justify="start" wrap>
            <Button
              isLoading={isDownloading}
              onClick={() =>
                treatment?.id && downloadTreatment({ id: treatment.id })
              }
            >
              {t('Treatment Export')}
            </Button>
          </Flex>
        </Flex>
        {isCancelledTreatment && treatment?.cancellationReason && (
          <CancellationReasons
            cancellationReason={treatment?.cancellationReason}
          />
        )}

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragOver={({ over }) => setDragOverId(over?.id ?? null)}
          onDragStart={({ active }) => {
            const item = procedures.find(
              p => p.treatmentProcedureId === active.id,
            )
            if (item) {
              setActiveDragItem(item)
            }
          }}
          onDragEnd={event => {
            setDragOverId(null)
            handleDragEnd(event)
            setActiveDragItem(null)
          }}
          onDragCancel={() => setActiveDragItem(null)}
        >
          <Table<ProcedureWithAppointmentType>
            data={procedures}
            columns={treatmentProceduresColumns(
              setProcedureToCancel,
              setProcedureToRestore,
            )}
            totalCount={procedures.length}
            totalPages={0}
            hiddenColumns={isCancelledTreatment || !isAdmin ? ['id'] : []}
            renderRow={(row: Row<ProcedureWithAppointmentType>) => (
              <DraggableRow
                key={row.id}
                row={row}
                dragOverId={dragOverId}
                getRowId={row => row.treatmentProcedureId}
              />
            )}
          />
          <DragOverlay>
            {activeDragItem ? (
              <Flex
                style={{
                  padding: '6px 12px',
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                  fontWeight: 500,
                  minWidth: 350,
                }}
              >
                {activeDragItem.name}
              </Flex>
            ) : null}
          </DragOverlay>
        </DndContext>
        <NewTreatmentProcedures
          isOpen={proceduresModalOpen}
          handleOpen={setProceduresModalOpen}
          treatmentId={String(treatment?.id)}
          refreshData={updateTreatment}
        />
        <TreatmentCancellation
          isOpen={cancelModalOpen}
          handleOpen={setCancelModalOpen}
          treatment={treatment as TreatmentType}
          refreshData={updateTreatment}
        />
        <CancelTreatmentProcedures
          treatmentId={String(treatment?.id)}
          procedureId={procedureToCancel}
          onClose={setProcedureToCancel}
          refreshData={updateTreatment}
        />
        <RestoreTreatmentProcedures
          treatmentId={String(treatment?.id)}
          procedureId={procedureToRestore}
          onClose={setProcedureToRestore}
          refreshData={updateTreatment}
        />
        <ScheduleAssistTreatment
          treatmentId={String(treatment?.id)}
          isOpen={treatmentScheduleAssistModalOpen}
          handleOpen={setTreatmentScheduleAssistModalOpen}
          refreshData={updateTreatment}
        />
        <MergeProcedure
          isOpen={!!mergePair}
          mergePair={mergePair}
          onClose={() => setMergePair(null)}
          refreshData={updateTreatment}
        />
      </Flex>
    </Card>
  )
}

export default TreatmentAppointmentsList
