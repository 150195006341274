import { PlusIcon } from '@radix-ui/react-icons'
import { Button, Flex, Text } from '@weareredlight/design-system'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { HolidayParamsType, HolidayResponseType } from 'types/holidays'

import DeleteHoliday from './DeleteHoliday'
import EditHoliday from './EditHoliday'
import { HolidayActions } from './utils'

import api from 'api/api'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { GenericPath, HolidaysPath } from 'router/enums'
import { holidaysColumns, holidaysDefaultSortees } from 'utils/tables/holidays'

const HolidaysList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const params = useMemo(
    () => ({
      organizationId: id || '',
    }),
    [id],
  )

  const { tableData, refreshTable, ...rest } = useTableRequestState<
    HolidayResponseType,
    HolidayParamsType
  >({
    requestFunc: api.getHolidays,
    pageSize: 10,
    params,
    sortees: holidaysDefaultSortees,
  })

  const [activeHoliday, setActiveHoliday] = useState<HolidayResponseType>()
  const [action, setAction] = useState<HolidayActions>()

  const handleAction = (
    holiday: HolidayResponseType,
    action: HolidayActions,
  ) => {
    setActiveHoliday(holiday)
    setAction(action)
  }

  return (
    <Flex direction="column" gap="xxlg" css={{ padding: '1.5rem' }}>
      <Flex justify="spaceBetween" css={{ width: '100%' }}>
        <Text variant="h3" color="accent">
          {t('Holidays')}
        </Text>
        <Button
          variant="tertiary"
          iconComponent={() => <PlusIcon />}
          onClick={() =>
            navigate(`${HolidaysPath.HOLIDAYS}/${GenericPath.NEW}`)
          }
        >
          {t('Add Holiday')}
        </Button>
      </Flex>
      <Table<HolidayResponseType>
        data={tableData?.data || []}
        columns={holidaysColumns(handleAction)}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
      <DeleteHoliday
        isOpen={action === HolidayActions.DELETE}
        onClose={setActiveHoliday}
        refreshData={refreshTable}
        holidayId={activeHoliday?.id || ''}
      />
      <EditHoliday
        isOpen={action === HolidayActions.EDIT}
        onClose={() => {
          setAction(undefined)
          setActiveHoliday(undefined)
        }}
        refreshData={refreshTable}
        holiday={activeHoliday}
        isLoading={false}
      />
    </Flex>
  )
}

export default HolidaysList
